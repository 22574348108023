import { render, staticRenderFns } from "./MyScopes.vue?vue&type=template&id=243fa277&scoped=true&"
import script from "./MyScopes.vue?vue&type=script&lang=js&"
export * from "./MyScopes.vue?vue&type=script&lang=js&"
import style0 from "./MyScopes.vue?vue&type=style&index=0&id=243fa277&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "243fa277",
  null
  
)

export default component.exports